
const errorMessage = {
  '204-1': '0筆資料更新',
  '400-0': '應輸入資料未輸入',
  '400-1': '必填欄位未輸入',
  '400-2': '欄位格式不符',
  '400-3': '欄位輸入長度不符',
  '400-4': '必填欄位不符合欄位邏輯',
  '400-5': '電子郵件格式錯誤',
  '400-6': '原密碼輸入錯誤',
  '400-7': '會員狀態編碼不存在',
  '400-8': '客戶編號錯誤',
  '400-9': '找不到商品照片資訊批次更新參數',
  '400-10': '驗證碼輸入錯誤',
  '400-11': '驗證碼失效，請重新點選圖片',
  '400-12': '找不到創建帳號所需資訊。請至少填入email或手機。',
  '401-0': '未經存取授權',
  '401-1': 'Facebook存取未經授權',
  '401-2': 'Google存取未經授權。',
  '403-0': '拒絕存取授權',
  '403-1': '帳號尚未啟用，請先啟用',
  '403-2': '帳號尚未啟用，請至Email信箱啟用',
  '403-3': '帳號尚未啟用，請點選簡訊啟用',
  '403-4': '本帳號已被管理者停權。請洽客服',
  '403-5': '會員已被管理者停權。請洽客服',
  '403-6': 'Facebook拒絕存取授權',
  '403-7': 'Google拒絕存取授權',
  '404-0': '查無資料',
  '404-1': '電子郵件位址不存在，請洽管理員',
  '404-2': '手機號碼不存在，請洽管理員',
  '404-3': '品牌不存在',
  '404-4': '驗證失敗，密碼錯誤',
  '404-5': '驗證失敗，帳號專屬驗證碼失效',
  '404-6': '查無此會員',
  '404-7': '帳號不存在',
  '404-8': '找不到系統設定',
  '404-10': 'Facebook找不到這個帳號資訊',
  '404-11': 'Google找不到這個帳號資訊',
  '404-12': '帳號不存在，請前往註冊',
  '409-1': '資料新增失敗',
  '409-2': '資料更新失敗',
  '409-3': '資料未更新',
  '409-4': '資料刪除失敗',
  '409-5': '未刪除任何資料',
  '409-6': '管理群組建立失敗',
  '409-7': '管理群組更新失敗',
  '409-8': '帳號建立失敗',
  '409-9': '帳號刪除失敗',
  '409-11': '帳號權限狀態更新失敗',
  '409-12': '帳號資訊更新失敗',
  '409-13': '密碼重設失敗，請洽客服',
  '409-14': '帳號已存在',
  '409-15': 'Email已存在。請重新輸入或直接登',
  '409-16': '手機號碼已存在。請重新輸入或直接登',
  '409-17': '身分證或護照號碼已存在。請重新輸入或直接登',
  '409-18': '帳號已重複存在，請洽管理員',
  '409-19': 'Email已重複存在(登入帳號重複)，請洽管理員',
  '409-20': '手機號碼已重複存在(登入帳號重複)，請洽管理員',
  '409-21': '帳號專屬驗證碼更新失敗',
  '409-22': '會員建立失敗',
  '409-23': '會員資訊建立失敗',
  '409-24': '會員重複存在，請洽管理員',
  '409-25': '會員已存在且已啟用',
  '409-26': '會員已存在，但尚未啟用',
  '409-27': '會員資訊更新失敗',
  '409-28': '會員消費資訊更新失敗',
  '409-29': '會員啟用失敗，請洽客服',
  '409-30': '購物車品項增加失敗',
  '409-31': '購物車品項更新失敗',
  '409-32': '購物車品項刪除失敗',
  '409-33': '購物車品已不存在',
  '409-34': '訂單成立失敗',
  '409-35': '訂單更新失敗',
  '409-36': '訂單狀態更新失敗',
  '409-37': '訂單配送狀態更新失敗',
  '409-38': '訂單託運單號更新失敗',
  '409-39': '訂單刪除失敗',
  '409-40': '訂單明細項目新增失敗',
  '409-41': '訂單明細項目更新失敗',
  '409-42': '訂單明細項目刪除失敗',
  '409-43': '配送服務資料新增失敗',
  '409-44': '配送服務資料更新失敗',
  '409-45': '配送限制新增失敗',
  '409-46': '配送限制已經存在',
  '409-47': '配送限制刪除失',
  '409-48': '簡訊發送失敗',
  '409-49': '系統設定新增失敗',
  '409-50': '系統設定更新失敗',
  '409-51': '金流啟用資訊更新失敗',
  '409-52': '商品大分類建立失敗',
  '409-53': '商品大分類更新失敗',
  '409-54': '商品子分類建立失敗',
  '409-55': '商品子分類更新失敗',
  '409-56': '商品品牌建立失敗',
  '409-57': '商品品牌名稱更新失敗',
  '409-58': '商品品牌刪除失敗',
  '409-59': '規格1更新失敗',
  '409-60': '規格2更新失敗',
  '409-61': '商品規格建立失敗',
  '409-62': '商品資訊建立失敗',
  '409-63': '商品資訊更新失敗',
  '409-64': '商品照片上傳失敗',
  '409-65': '商品照片資訊更新失敗',
  '409-66': '規格1更新失敗',
  '409-67': '規格2更新失敗',
  '409-68': '定價庫存更新失敗',
  '409-69': '定價庫存規格無法刪除。已有訂單消費記錄綁定',
  '409-70': '規格1無法刪除。已有訂單消費記錄綁定',
  '409-71': '規格2無法刪除。已有訂單消費記錄綁定',
  '409-72': '商品描述規格建立失敗',
  '409-73': '商品描述規格更新失敗',
  '409-74': '商品描述規格批次更新失敗',
  '409-75': '庫存數量更新失敗',
  '409-76': '商品方案庫存數量更新失敗',
  '409-77': '商品文案建立失敗',
  '409-78': '商品文案照片上傳失敗',
  '409-79': '商品文案更新失敗',
  '409-80': '聯絡訊息建立失敗',
  '409-81': '聯絡訊息更新失敗',
  '500-0': '系統錯誤程式碼',
  '500-1': '無法進入資料資料庫',
  '500-2': '加密機制發生系統錯誤',
  '500-3': 'Facebook系統發生錯誤',
  '500-4': 'Google系統發生錯誤'
}

const getErrorMessage = (response) => {
  const errorCode = response.data.errMsg.split(':')[0]

  if (errorMessage[errorCode]) {
    return errorMessage[errorCode]
  } else if (response.data.errMsg) {
    return response.data.errMsg
  } else {
    switch (response.config.method) {
      case 'put':
        return '更新失敗'
      case 'delete':
        return '刪除失敗'
      case 'post':
      default:
        return '新增失敗'
    }
  }
}

const getSuccessMessage = (response) => {
  switch (response.config.method) {
    case 'put':
      return '更新成功'
    case 'delete':
      return '刪除成功'
    case 'post':
    default:
      return '新增成功'
  }
}

const useErrorMessage = () => {
  return { getErrorMessage, getSuccessMessage }
}

export {
  useErrorMessage
}

import { helpers, sameAs, required, email, numeric, between, or } from '@vuelidate/validators'

const requiredHelper = helpers.withMessage(
  '必填欄位',
  required
)

const emailHelper = helpers.withMessage(
  '格式不符',
  email
)

const numericHelper = helpers.withMessage(
  '請填入數字',
  numeric
)

const mobileHelper = helpers.withMessage(
  '請輸入格式09開頭的十位數字',
  // '請輸入格式09XX-XXX-XXX',
  // helpers.regex(/09\d{2}\-?\d{3}\-?\d{3}/)
  // eslint-disable-next-line
  helpers.regex(/09\d{8}/)
)

const passwordHelper = helpers.withMessage(
  '密碼長度為8-16位英數文字，至少1個英文字母',
  helpers.regex(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/)
)

export {
  helpers, sameAs, requiredHelper, passwordHelper, emailHelper, numericHelper, mobileHelper, between, or
}
